<template>
  <div>
    <b-modal
      id="modal-payment"
      hide-header
      hide-footer
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      title="Large Modal"
      class="custom__modal"
    >
      <ModalHeader
        :left-text="'Kembali'"
        :middle-text="
          customer_id
            ? 'Silahkan Pilih Metode Withdraw Deposit Anda'
            : 'Silahkan Pilih Metode Top-up Deposit Anda'
        "
        :left-function="directPage"
        :is-rigt-column="true"
        :is-disabled="isLoading"
        :right-function="customer_id ? submitWithdrawal : submitTopup"
        :right-text="'Simpan'"
      />
      <div class="form-payment mt-5 pt-5">
        <b-container>
          <b-row class="justify-content-center align-items-center">
            <b-col cols="8">
              <b-row class="mt-5 pt-5">
                <b-col
                  v-for="(item, index) in payments"
                  :key="index"
                  class="px-0"
                  cols="6"
                >
                  <b-card class="p-2 m-1" no-body style="border-radius: 12px">
                    <b-form-checkbox
                      v-model="payment_method"
                      :value="item.value"
                      class="custom-control-success custom__checkbox"
                    >
                      <div role="button" class="d-flex align-items-center">
                        <div
                          class="d-flex justify-content-center align-items-center rounded-circle image-box box-tempo"
                        >
                          <b-img
                            :src="
                              require(`@/assets/images/icons/${item.image}.svg`)
                            "
                            blank-color="#ccc"
                            width="18"
                            alt="placeholder"
                          />
                        </div>
                        <div class="ml-1">
                          <h6 class="text-dark fw-bold-800">
                            {{ item.name }} <span class="text-danger">*</span>
                          </h6>
                          <h6
                            class="text-dark text-darken-4 fw-bold-500 size12"
                          >
                            Pembayaran Langsung
                          </h6>
                        </div>
                      </div>
                    </b-form-checkbox>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BContainer,
  BFormCheckbox,
  BCard,
  BImg,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
import ModalHeader from "@/components/ModalHeader.vue";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BContainer,
    BFormCheckbox,
    BCard,
    BImg,
    ModalHeader,
  },
  props: {
    getData: {
      type: Function,
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      payment_method: "",
      payments: [
        {
          name: "Tunai",
          value: "cash",
          image: "tunai",
          class: "image-box box-tempo",
        },
        {
          name: "Transfer Bank",
          value: "transfer",
          image: "transfer",
          class: "image-box box-transfer",
        },
        {
          name: "Kredit atau Debit",
          value: "card",
          image: "kredit",
          class: "image-box box-kredit",
        },
        {
          name: "Giro",
          value: "giro",
          image: "giro",
          class: "image-box box-giro",
        },
      ],
      formPayload: {},
      customer_supplier_uuid: "",
      isLoading: false,
      customer_id: localStorage.customer_value,
    };
  },
  watch: {
    "$store.state.customer.formPayloadTopup": {
      handler(value) {
        this.formPayload = value;
      },
      deep: true,
    },
    "$store.state.customer.customer": {
      handler(value) {
        localStorage.setItem("customer_uuid", value);
      },
      deep: true,
    },
    payment_method(value) {
      this.formPayload.payment_method = value;
      // this.$store.commit('stockWarehouse/setPaymentMethod', value)
    },
  },
  mounted() {
    this.formPayload = this.$store.state.customer.formPayloadTopup;
  },
  destroyed() {},
  methods: {
    submitTopup() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apa Anda yakin ingin melakukan top-up?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          const form = this.preparePayload();
          this.$store
            .dispatch("customer/postData", {
              payload: form,
              query: `/${
                localStorage.customer_uuid ||
                this.$store.state.customer.customer.uuid
              }/add-balance`,
            })
            .then(() => {
              this.isLoading = false;
              this.getData(localStorage.customer_uuid);
              this.$store.commit("customer/setUpdateData", true);
              // localStorage.customer_uuid = ''
              successNotification(this, "Success", "Topup berhasil");
              this.$bvModal.hide("modal-payment");
              this.$bvModal.hide("modal-deposit");
            })
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
        }
      });
    },
    submitWithdrawal() {
      console.log("test");
      this.$swal({
        title: "Konfirmasi",
        text: "Apa Anda yakin ingin melakukan withdraw?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          const form = this.preparePayload();
          this.$store
            .dispatch("customer/postData", {
              payload: form,
              query: `/${
                localStorage.customer_value ||
                this.$store.state.customer.customer.uuid
              }/withdraw-balance`,
            })
            .then(() => {
              this.isLoading = false;
              this.getData(localStorage.customer_value);
              this.$store.commit("customer/setUpdateData", true);
              // localStorage.customer_uuid = ''
              successNotification(this, "Success", "Withdraw berhasil");
              localStorage.removeItem("customer_value");
              this.$bvModal.hide("modal-payment");
              this.$bvModal.hide("modal-deposit");
            })
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
        }
      });
    },
    preparePayload() {
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          form.append(key, this.formPayload[key]);
        }
      }
      return form;
    },
    directPage() {
      this.$bvModal.hide("modal-payment");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
.form-payment {
  .custom-checkbox {
    .custom-control-label::after,
    .custom-control-label::before {
      right: 4%;
      top: 35%;
      left: inherit;
    }
    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: none !important;
    }
    .custom-control-label::before {
      border: none;
    }
  }
  .custom-control-label {
    width: 100%;
  }
  .custom-checkbox.custom-control {
    padding-left: 0;
  }
  .image-box {
    width: 46px;
    height: 46px;
  }
  .card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }
}
#modal-payment {
  .modal-body {
    background: #f6f7fa;
  }
}
</style>
